import { useAuthStore } from '~/stores/auth'
import { useAlertStore } from '~/stores/alert'

export default defineNuxtRouteMiddleware(async (_to, _from) => {
  const { user } = useAuthStore()
  const alertStore = useAlertStore()

  if (user) {
    if (process.server) {
      alertStore.setAlert('You were redirected, because you are not authorized to view this page', 'error')
      return navigateTo('/')
    }

    return abortNavigation()
  }
})
